import React, { useState } from 'react'
import { withFirebase } from '../firebase'
import { Link } from 'gatsby'
import { Formik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'

import {
  Button,
  FormBox,
  FormFields,
  FormField,
  FormError,
  FormCopy,
} from '../page/components'

const StyledTextField = styled(TextField)`
  ${props => props.theme.materialTextField()}
`

const userSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address.')
    .required('Please enter your email address.'),
})

const INITIAL_STATUS = {
  submitting: false,
  submitted: false,
  submitErrors: [],
}

export const ForgotPassForm = ({ firebase }) => {
  const [status, setStatus] = useState(INITIAL_STATUS)

  const { submitting, submitted, submitErrors } = status

  let content = null
  if (submitted && submitErrors.length === 0) {
    content = (
      <FormBox>
        <FormFields>
          <FormField width={`100%`}>
            <FormCopy>
              <p>
                A password reset email has been sent. Sign in{' '}
                <Link to="/sign-in">here</Link>.
              </p>
            </FormCopy>
          </FormField>
        </FormFields>
      </FormBox>
    )
  } else {
    const errorContent =
      submitErrors.length > 0
        ? submitErrors.map((e, index) => <p key={`erorr-${index}`}>{e}</p>)
        : null

    content = (
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={userSchema}
        onSubmit={values => {
          const { email } = values
          // console.log('Form Submit', email)
          setStatus(prevState => ({
            ...prevState,
            submitting: true,
            submitErrors: [],
          }))

          firebase.auth
            .sendPasswordResetEmail(email)
            .then(function() {
              setStatus(prevState => ({
                ...prevState,
                submitting: false,
                submitted: true,
                submitErrors: [],
              }))
            })
            .catch(function(error) {
              const { message } = error
              setStatus(prevState => ({
                ...prevState,
                submitting: false,
                submitted: true,
                submitErrors: [message],
              }))
            })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <FormBox processing={submitting}>
            <form onSubmit={handleSubmit}>
              <FormFields>
                <FormField width={`100%`}>
                  <FormCopy>
                    <p>
                      Confirm your email address and a password reset email will
                      be sent.
                    </p>
                  </FormCopy>
                </FormField>
                <FormField width={`100%`}>
                  <StyledTextField
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    margin="normal"
                  />
                  {errors.email && touched.email ? (
                    <FormError>{errors.email}</FormError>
                  ) : null}
                </FormField>
                {errorContent}
                <FormField marginRight={`50%`}>
                  <Button type="submit">Submit</Button>
                </FormField>
              </FormFields>
            </form>
          </FormBox>
        )}
      </Formik>
    )
  }

  return content
}

export default withFirebase(ForgotPassForm)
