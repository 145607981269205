import React from 'react'
import SEO from '../components/SEO'
import { Wrap } from '../components/page/components'
import FocusHeader from '../components/global/focus-header'
import FocusBody from '../components/page/focus-body'
import ForgotPassForm from '../components/account/forgot-pass-form'

export default () => {
  return (
    <Wrap>
      <SEO title="Forgot Password" />
      <FocusHeader title="Forgot Password" backUrl="/" />
      <FocusBody>
        <ForgotPassForm />
      </FocusBody>
    </Wrap>
  )
}
